<template>
  <div>
    <b-card>
      <b-row>

        <b-col md="2">
          <b-form-group>
            <label>Tahun Anggaran</label>
            <v-select v-model="input.tahun" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="th"
              @input="rekap_pagu_anggaran()" :options="tahun_anggaran" />
          </b-form-group>

        </b-col>
        <b-col md="8">
          <b-form-group>
            <label>Balai/Bidang</label>
            <v-select v-model="input.id_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
              @input="rekap_pagu_anggaran()" size="sm" :options="balai" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="warning" block @click="clear()" class="mt-2">
            <feather-icon icon="RefreshCcwIcon" size='15' /> Clear
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row v-show="items != ''">
      <b-col>
        <b-card>
          <tabel-data :tahun_anggaran="tahun_anggaran" :program="program" :items=items
            @rekap_pagu_anggaran=rekap_pagu_anggaran></tabel-data>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCard, BCol, BRow,
  BTab, BButton,
} from 'bootstrap-vue'
import TabelData from './component/tabelData.vue'
export default {
  components: {
    BTabs, BButton,
    BTab, BCard, BCol, BRow,
    TabelData, vSelect
  },
  props: {

  },
  data() {
    return {
      input: {
        id_bidang: {},
        tahun: {}
      },
      tahun_anggaran: [],
      pagu_anggaran: [],
      items: [],
      program: [], balai: []

    }
  },
  mixins: [Base],
  mounted() {
    this.load_tahun_anggaran();
    this.load_program();
    this.master_bidang_bpj();

  },
  methods: {
    async master_bidang_bpj() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/laporan/master_bidang_bpj',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balai = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    clear() {
      this.input = {
        id_bidang: {},
        tahun: {}
      },
        this.items = []
    },
    async load_tahun_anggaran() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/laporan/tahun_anggaran',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.tahun_anggaran = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async rekap_pagu_anggaran(val) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/laporan/rekap_pagu_anggaran_program',
        data: {
          th: self.input.tahun.th,
          id_bidang: self.input.id_bidang.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_program(val) {
      const self = this;
      await axios({
        method: 'PUT',
        url: 'api/pu/laporan/load_program',
        data: {
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.program = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style></style>