<template>
  <b-row>

    <b-col md="4" sm="8" class="my-1">

    </b-col>
    <b-col md="2">

    </b-col>
    <b-col md="2" sm="4" class="my-1">

    </b-col>
    <b-col md="4" class="my-1  ">
      <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Pencarian" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>

            <b-col md="2" class="text-primary">
              <small>Kode </small>
              <h6 class="text-primary"> {{ data.item.kode_unsur }}. {{ data.item.kode_bidang }}. {{ data.item.kode_program
              }} </h6>
            </b-col>
            <b-col md="7">
              <small class="text-primary">Nama Program Kerja</small>
              <h6 class="text-primary"> {{ data.item.nama_program }} </h6>


            </b-col>

            <b-col md="3">

              <h4><sup>Rp. </sup> <strong class="text-primary" style="float: right;">{{ Rp(data.item.pagu_usulan)
              }}</strong>
                <br>
                <!-- <small style="float: right;">{{ Rp(data.item.jm_sub_kegiatan) }} Sub Kegiatan</small> -->
              </h4>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, vSelect
  },
  data() {
    return {
      th_anggaran: {},
      perPage: 50,
      pageOptions: [30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
    }
  },
  props: {
    items: {},
    tahun_anggaran: {}
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ['rekap_pagu_anggaran'],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    LoadData() {
      this.$emit('rekap_pagu_anggaran', this.th_anggaran)
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>